import { Card } from "../../library/card";
import { Carousel, CarouselBody, CarouselItem } from "../../library/carousel";
import { Fragment, useContext } from "react";
import { Language } from "../../context/eventContext";
import { Roots } from "../../config/root";
import { useTranslation } from "react-i18next";
function Company() {

    const today = new Date();
    const { t, i18n } = useTranslation();
    const { eventdata } = useContext(Language)
    const eventdate = (eventdata || []).filter(d => new Date(d.date) <= today).sort((a, b) => new Date(b.date) - new Date(a.date))
    return (
        <>
            <Carousel>
                {
                    (eventdate || [])
                        .filter((f) => { return (f.slide === 1) })
                        .map((d, x) => {
                            return (
                                <Fragment key={`carousel-list-${d.id}-list-${x}`}>
                                    <CarouselItem image={`${Roots({ is: "file" })}/event/${JSON.parse(d.media) ? JSON.parse(d.media).slide : ""}`}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-8 col-sm-8 col-8">
                                                    <CarouselBody
                                                        id={d.gid}
                                                        title={d.title}
                                                        subtitle={d.subtitle}
                                                        location={d.addres}
                                                        date={d.date}
                                                        time={d.time}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </CarouselItem>
                                </Fragment>
                            )
                        })
                }
            </Carousel>
            <div className="bg-gray-200">
                <div className="container">
                    <div className="row pt-10 pb-10 d-flex justify-content-center">
                        <div className="col-12 mb-6 mt-4">
                            <div className="section-title">{t('section.home.title')}</div>
                        </div>
                        {
                            (eventdate || [])
                                .filter((f) => { return (f.slide === 0) })
                                .map((d, x) => {
                                    return (
                                        <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-12 mb-4">
                                            <Card
                                                id={d.gid}
                                                title={d.title}
                                                subtitle={d.subtitle}
                                                location={d.addres}
                                                date={d.date}
                                                time={d.time}
                                                image={`${Roots({ is: "file" })}/event/${JSON.parse(d.media) ? JSON.parse(d.media).event : ""}`}
                                            />
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Company;