import { useTranslation } from "react-i18next";
import { Roots } from "../../config/root";
import { useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { formatdate } from '../../utils/formatdate';
import { Controldate } from '../../utils/controldate';
function Detail() {

    const { t, i18n } = useTranslation();
    const location = useParams();
    const [detaildata, setdetaildata] = useState([])
    const get_data = async (lang, id) => {
        const response = await axios.get(`${Roots({ is: "api" })}?params=detail&language=${parseInt(lang)}&id=${parseInt(id)}`)
        setdetaildata(response.data.data)
    }
    useEffect(() => {
        get_data(i18n.language === "de" ? 1 : 2, location.id)
    }, [location.id, i18n.language])


    const DeclareJSON = ({ data = null, field }) => {
        if (JSON.parse(data) === null) {
            return ""
        } else {
            return JSON.parse(data)[field]
        }
    }

    return (
        <>
            <div>
                {
                    (detaildata || []).map((d, x) => {
                        return (
                            <Fragment>

                                {/* BANNER */}
                                <div className="carousel-image d-flex align-items-center" style={{ backgroundImage: `url(${Roots({ is: "file" })}/event/${DeclareJSON({ data: d.media, field: "poster" })})` }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-8 col-sm-9 col-7">
                                                <div className="carousel-title mb-3">{d.title}</div>
                                                <div className="carousel-info">{d.subtitle}</div>
                                                <div className="carousel-location mb-3 mt-3">
                                                    <div className="w-15px d-flex justify-content-center align-items-center">
                                                        <i className="fa-solid fa-location-dot fs-2 text-white"></i>
                                                    </div>
                                                    <div className="w-100 ms-3">
                                                        {d.addres}
                                                    </div>
                                                </div>
                                                <div className="carousel-date mb-3">
                                                    <div className="w-15px d-flex justify-content-center align-items-center">
                                                        <i className="fa-solid fa-calendar fs-2 text-white"></i>
                                                    </div>
                                                    <div className="w-100 ms-3">
                                                        {formatdate(d.date).dayOfWeek}, {formatdate(d.date).formattedDate}
                                                    </div>
                                                </div>
                                                <div className="carousel-time mb-3">
                                                    <div className="w-15px d-flex justify-content-center align-items-center">
                                                        <i className="fa-solid fa-clock fs-2 text-white"></i>
                                                    </div>
                                                    <div className="w-100 ms-3">
                                                        {formatdate(d.time).formattedTime}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container pt-10 pb-5">
                                    {/* BIOGRAFY */}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="h-100 card-flush d-flex align-items-center">
                                                <img src={`${Roots({ is: "file" })}/event/${JSON.parse(d.media) ? JSON.parse(d.media).biografy : ""}`} className="img-fluid rounded-4 card-flush border h-100" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="h-100 card-flush d-flex align-items-center">
                                                <div>
                                                    <div className="fs-1 fw-bolder mb-2 mt-2">{d.title}</div>
                                                    <div className="fs-3 fw-semibold mb-2">{d.subtitle}</div>
                                                    <div style={{ fontSize: "1.4rem" }} dangerouslySetInnerHTML={{ __html: d.text }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* PROGRAM */}
                                    <div className="row pt-10 pb-5" hidden={DeclareJSON({ data: d.program, field: "title" }) ? false : true}>
                                        <div className="col-lg-12">
                                            <div className="fs-1 fw-bolder mb-4 text-center">
                                                {DeclareJSON({ data: d.program, field: "title" })}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div className="card h-100 card-flush">
                                                <div className="card-body">
                                                    {
                                                        (DeclareJSON({ data: d.program, field: "data" }) || []).filter((d) => { return (d.section === 1) }).map((m, y) => {
                                                            return (
                                                                <>
                                                                    <div className="fs-1 fw-bold text-gray-800">{m.stage}</div>
                                                                    <div className="row pt-4">
                                                                        {
                                                                            (m.composer || []).map((s, u) => {
                                                                                return (
                                                                                    <div className="col-lg-12 mb-6">
                                                                                        <div className="fs-4 fw-semibold text-gray-600 mb-2">{s.name} {s.date}</div>
                                                                                        <div className="fs-2 fw-bold text-gray-800">{s.album}</div>
                                                                                        <div className="row pt-2">
                                                                                            {
                                                                                                (s.lists || []).map((t, u) => {
                                                                                                    return (
                                                                                                        <div className="col-lg-12 mb-2">
                                                                                                            <div className="d-flex h-30px align-items-center">
                                                                                                                <i className="fa-solid fa-circle-right me-2 fs-4"></i>
                                                                                                                <div className="fs-4 fw-semibold text-gray-700">{t.name}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div className="card h-100 card-flush">
                                                <div className="card-body">
                                                    {
                                                        (DeclareJSON({ data: d.program, field: "data" }) || []).filter((d) => { return (d.section === 2) }).map((m, y) => {
                                                            return (
                                                                <>
                                                                    <div className="fs-1 fw-bold text-gray-800">{m.stage}</div>
                                                                    <div className="row pt-4">
                                                                        {
                                                                            (m.composer || []).map((s, u) => {
                                                                                return (
                                                                                    <div className="col-lg-12 mb-6">
                                                                                        <div className="fs-4 fw-semibold text-gray-600 mb-2">{s.name} {s.date}</div>
                                                                                        <div className="fs-2 fw-bold text-gray-800">{s.album}</div>
                                                                                        <div className="row pt-2">
                                                                                            {
                                                                                                (s.lists || []).map((t, u) => {
                                                                                                    return (
                                                                                                        <div className="col-lg-12 mb-2">
                                                                                                            <div className="d-flex h-30px align-items-center">
                                                                                                                <i className="fa-solid fa-circle-right me-2 fs-4"></i>
                                                                                                                <div className="fs-4 fw-semibold text-gray-700">{t.name}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TAGS */}
                                    <div className="row pt-10 pb-5" hidden={DeclareJSON({ data: d.tags, field: "title" }) ? false : true}>
                                        <div className="col-lg-12">
                                            <div className="fs-1 fw-bolder mb-4 text-center">
                                                {DeclareJSON({ data: d.tags, field: "title" })}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-2">
                                            <div className="card h-100 card-flush">
                                                <div className="card-body p-0">
                                                    <div className="h-100 card-flush w-100 bg-dark background-fag rounded-2" style={{ backgroundImage: `url(${Roots({ is: "file" })}/event/${DeclareJSON({ data: d.tags, field: "image" }) ? DeclareJSON({ data: d.tags, field: "image" }) : ""})` }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-2">
                                            <div className="h-100 card-flush">
                                                <div className="row pt-4">
                                                    {
                                                        (DeclareJSON({ data: d.tags, field: "data" }) || []).map((m, y) => {
                                                            return (
                                                                <div className={`col-lg-${DeclareJSON({ data: d.tags, field: "data" }).length - 1 === y ? "12" : "6"} mb-1`}>
                                                                    <div className="card">
                                                                        <div className="card-body pt-5 pb-5">
                                                                            <div className="fs-6 fw-bold text-gray-600">{m.jobs}</div>
                                                                            <div className="fs-3 fw-bold">{m.name}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* VIDEO */}
                                    <div className="row pt-10 pb-5" hidden={DeclareJSON({ data: d.video, field: "title" }) ? false : true}>
                                        <div className="col-lg-12">
                                            <div className="fs-1 fw-bolder mb-4 text-center">
                                                {DeclareJSON({ data: d.video, field: "title" })}
                                            </div>
                                        </div>
                                        {
                                            (DeclareJSON({ data: d.video, field: "data" }) || []).map((d, x) => {
                                                return (
                                                    <div className="col-lg-6 mb-2">
                                                        <iframe style={{ height: "400px", width: "100%", borderRadius: "12px" }} src={`https://www.youtube.com/embed/${d}`}></iframe>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* GALERY */}
                                    <div className="row pt-10 pb-5" hidden={DeclareJSON({ data: d.galery, field: "title" }) ? false : true}>
                                        <div className="col-lg-12">
                                            <div className="fs-1 fw-bolder mb-4 text-center">
                                                {DeclareJSON({ data: d.galery, field: "title" })}
                                            </div>
                                        </div>
                                        {
                                            (DeclareJSON({ data: d.galery, field: "data" }) || []).map((d, x) => {
                                                return (
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="card background-galery" style={{ backgroundImage: `url(${Roots({ is: "file" })}/galery/${d})` }}>
                                                            <div className="card-body">
                                                                <div className="h-350px" ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* SPONSOR */}
                                    <div className="row pt-10 pb-5" hidden={DeclareJSON({ data: d.sponsor, field: "title" }) ? false : true}>
                                        <div className="col-lg-12">
                                            <div className="fs-1 fw-bolder mb-4 text-center">
                                                {DeclareJSON({ data: d.sponsor, field: "title" })}
                                            </div>
                                        </div>
                                        {
                                            (DeclareJSON({ data: d.sponsor, field: "data" }) || []).map((d, x) => {
                                                return (
                                                    <div className="col-lg-3 mb-2">
                                                        <div className="card p-10 pt-0 pb-0">
                                                            <div className="card-body background-sponsor p-0" style={{ backgroundImage: `url(${Roots({ is: "file" })}/sponsor/${d.image})` }}>
                                                                <div className="h-150px" ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })
                }
            </div>
        </>
    )
}
export default Detail;