export function Seo(e) {

    const turkishMap = {
        'ç': 'c',
        'Ç': 'C',
        'ğ': 'g',
        'Ğ': 'G',
        'ı': 'i',
        'I': 'I',
        'İ': 'i',
        'ö': 'o',
        'Ö': 'O',
        'ş': 's',
        'Ş': 'S',
        'ü': 'u',
        'Ü': 'U',
        ' ': '-',
        '/': '-',
        '&': 've',
        '.': '-',
    };

    return e
        .toLowerCase()
        .replace(/[çğışüöÇĞİŞÜÖ&/. ]/g, function (m) { return turkishMap[m]; })
        .replace(/[^a-z0-9\-]/g, '')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '');
}