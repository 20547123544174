import "./assets/css/plugins.bundle.css"
import "./assets/css/style.bundle.css"
import "./assets/css/kompass.style.css"
import "./assets/css/all.css"
import "./assets/css/brands.css"
import "./assets/css/fontawesome.css"
import "./assets/css/fonts.css"
import "./assets/css/regular.css"
import "./assets/css/solid.css"
import "./assets/css/global.css"

import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/index";
import Company from "./pages/company/index";
import Event from "./pages/events/index";
import Contact from "./pages/contact/index";
import { Layout } from "./module/layout"
import { Header } from "./module/header"
import { Footer } from "./module/footer"
import { useEffect } from "react"
import { LanguageProvider } from "./context/eventContext"
import { MenuProvider } from "./context/menuContext"
import Accreditation from "./pages/accreditation"
import Sss from "./pages/sss"
import { AboutProvider } from "./context/aboutContext"
import { FaqProvider } from "./context/faqContext"
import { AutoUpper, UpperButton } from "./utils/scrolltop"
import Detail from "./pages/detail"


function App() {
  useEffect(() => {
    document.body.setAttribute("data-kt-app-layout", "dark-sidebar")
    document.body.setAttribute("data-kt-app-header-fixed", "true")
    document.body.setAttribute("data-kt-app-sidebar-enabled", "true")
    document.body.setAttribute("data-kt-app-sidebar-fixed", "true")
    document.body.setAttribute("data-kt-app-sidebar-hoverable", "true")
    document.body.setAttribute("data-kt-app-sidebar-push-header", "true")
    document.body.setAttribute("data-kt-app-sidebar-push-toolbar", "true")
    document.body.setAttribute("data-kt-app-sidebar-push-footer", "true")
    document.body.setAttribute("data-kt-app-toolbar-enabled", "true")
    document.documentElement.setAttribute("data-bs-theme", "light");
  }, [])


  return (
    <>
      <MenuProvider>
        <LanguageProvider>
          <AboutProvider>
            <FaqProvider>
              <Layout>
                <Header />
                <AutoUpper />
                <Routes>
                  <Route path={"/"} element={<Home />} />
                  <Route path={"/uber-uns"} element={<Company />} />
                  <Route path={"/hakkimizda"} element={<Company />} />
                  <Route path="/aktuell" element={<Event />} />
                  <Route path="/etkinlikler" element={<Event />} />
                  <Route path="/kontakt" element={<Contact />} />
                  <Route path="/iletisim" element={<Contact />} />
                  <Route path="/akkreditierung" element={<Accreditation />} />
                  <Route path="/akreditasyon" element={<Accreditation />} />
                  <Route path="/sss" element={<Sss />} />

                  <Route path="/:name/:id" element={<Detail />} />
                  <Route path="*" element={<Home />} />
                </Routes>
                <Footer />
                <UpperButton />
              </Layout>
            </FaqProvider>
          </AboutProvider>
        </LanguageProvider>
      </MenuProvider>
    </>
  );

}

export default App;
