import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Roots } from '../config/root';
import { useTranslation } from 'react-i18next';
export const About = createContext();

export const AboutProvider = ({ children }) => {

    const [aboutstate, setaboutstate] = useState(1)
    const [aboutdata, setaboutdata] = useState([])
    const get_data = async (lang) => {
        const response = await axios.get(`${Roots({ is: "api" })}?params=about&language=${lang}`)
        setaboutdata(response.data.data)
    }
    
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setaboutstate(i18n.language === "de" ? 1 : 2)
    }, [i18n.language])

    useEffect(() => {
        get_data(aboutstate)
    }, [aboutstate])
    return (
        <About.Provider value={{ aboutdata, setaboutstate }}>
            {children}
        </About.Provider>
    );
};