import { useContext } from "react"
import FooterLogo from "../assets/media/footer-logo.png"
import { Menu } from "../context/menuContext"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

export function Footer() {
    const router = useNavigate()
    const { menudata } = useContext(Menu)
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-12 mb-6 mt-15">
                            <div className="section-title text-white">{t("footer.section4.title")}</div>
                        </div>
                        <div className="col-lg-8">
                            <div className="d-flex">
                                <div className="w-100">
                                    <input className="form-control rounded-0 h-60px" placeholder="E-posta adresinizi girin." />
                                </div>
                                <div className="w-200px">
                                    <button className="btn btn-kompass rounded-0 h-60px text-white w-100">{t("button.subscriber")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-10">
                        <div className="col-lg-2 mb-3 text-center">
                            <img src={FooterLogo} style={{ height: "180px" }} />
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="footer-menu-title">KOMPASS EVENTS</div>
                            <div className="d-flex justify-content-center">
                                <div className="footer-menu-separetor"></div>
                            </div>
                            <div className="footer-menu-list">
                                <div className="footer-menu-item">Schaufelbergerstrasse 57, CH-8055 Zürich</div>
                                <div className="footer-menu-item">+41 (0) 44 576 60 80</div>
                                <div className="footer-menu-item">info@kompass.events</div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="footer-menu-title">{t("footer.section2.title")}</div>
                            <div className="d-flex justify-content-center">
                                <div className="footer-menu-separetor"></div>
                            </div>
                            <div className="footer-menu-list">
                                <div className="footer-menu-item">{t("footer.section2.subtitle")}</div>
                                <div className="footer-menu-item">
                                    <i className="fa-brands fa-square-facebook text-white me-2 ms-2 fs-3hx"></i>
                                    <i className="fa-brands fa-square-instagram text-white me-2 ms-2 fs-3hx"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 mb-3">
                            <div className="footer-menu-title">{t("footer.section3.title")}</div>
                            <div className="d-flex justify-content-center">
                                <div className="footer-menu-separetor"></div>
                            </div>
                            <div className="footer-menu-list">
                                {(menudata || []).map((d, x) => { return (<div key={`footer-list-${d.id}-list-${x}`} className="footer-menu-item cursor-pointer" onClick={() => { router(d.path) }}>{d.name}</div>) })}
                            </div>
                        </div>
                        <div className="col-lg-12 pt-5">
                            <div className="d-flex justify-content-center">
                                <div className="footer-menu-separetor w-100"></div>
                            </div>
                            <div className="footer-menu-list pt-5 pb-5">
                                <div className="footer-menu-item">© 2024 KOMPASS EVENTS.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}