import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper/modules";
import 'swiper/css';
import { useCallback, useRef } from 'react';
import { formatdate } from '../utils/formatdate';
import { Controldate } from '../utils/controldate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Seo } from '../utils/seo';

export function Carousel({ children }) {
    const sliderRef = useRef(null);
    const Prev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);
    const Next = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <>
            <div className='carousel-container'>
                <Swiper
                    loop
                    ref={sliderRef}
                    spaceBetween={50}
                    slidesPerView={4}
                    autoplay={{ delay: 2500 }}
                    modules={[Pagination, Autoplay]}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        400: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        525: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        578: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                    }}
                >
                    {
                        (children || []).map((d, x) => {
                            return (
                                <SwiperSlide key={`carousel-${x}`}>
                                    <div className='carousel-image bg-dark' style={{ backgroundImage: `url(${d.props.children.props.image})` }}>
                                        {d.props.children}
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                <div className='carousel-previous-button cursor-pointer' onClick={Prev} hidden={children.length > 1 ? false : true}>
                    <i className="fa-solid fa-caret-left fs-2hx"></i>
                </div>
                <div className='carousel-next-button cursor-pointer' onClick={Next} hidden={children.length > 1 ? false : true}>
                    <i className="fa-solid fa-caret-right fs-2hx"></i>
                </div>
            </div >
        </>
    )
}
export function CarouselBody({
    id = "",
    title = "",
    subtitle = "",
    location = "",
    date = "",
    time = ""
}) {

    const router = useNavigate()
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="carousel-title mb-2">{title}</div>
            <div className="carousel-info">{subtitle}</div>
            <div className="carousel-location mb-3 mt-3">
                <div className="w-15px d-flex justify-content-center align-items-center">
                    <i className="fa-solid fa-location-dot fs-2 text-white"></i>
                </div>
                <div className="w-100 ms-3">
                    {location}
                </div>
            </div>
            <div className="carousel-date mb-3">
                <div className="w-15px d-flex justify-content-center align-items-center">
                    <i className="fa-solid fa-calendar fs-2 text-white"></i>
                </div>
                <div className="w-100 ms-3">
                    {formatdate(date).dayOfWeek}, {formatdate(date).formattedDate}
                </div>
            </div>
            <div className="carousel-time mb-3">
                <div className="w-15px d-flex justify-content-center align-items-center">
                    <i className="fa-solid fa-clock fs-2 text-white"></i>
                </div>
                <div className="w-100 ms-3">
                    {formatdate(time).formattedTime}
                </div>
            </div>
            <div className='carousel-button mt-10' hidden={!Controldate(date)} onClick={() => { router(`${Seo(title)}/${id}`) }}>
                <div className='border border-gray-200 h-45px w-xxl-200px w-xl-200px w-lg-250px w-100 me-2 d-flex align-items-center justify-content-center text-white rounded-1 cursor-pointer bg-hover-white text-hover-dark fw-bold'>
                    {t('button.detail')}
                </div>
            </div>
            <div className='carousel-button mt-10' hidden={Controldate(date)}>
                <div className='border border-gray-200 h-45px w-xxl-200px w-xl-200px w-lg-250px w-100 me-2 d-flex align-items-center justify-content-center text-white rounded-1 cursor-pointer bg-hover-white text-hover-dark fw-bold'>
                    {t("button.detail")}
                </div>
                <div className='border border-gray-200 h-45px w-xxl-200px w-xl-200px w-lg-250px w-100 me-2 d-flex align-items-center justify-content-center text-white rounded-1 cursor-pointer bg-hover-white text-hover-dark fw-bold'>
                    {t('button.ticket')}
                </div>
            </div>
        </>
    )
}
export function CarouselItem({ children, image }) {
    return children
}