import { useTranslation } from "react-i18next";
import { Roots } from "../../config/root";
import { Layout } from "../../module/layout";

function Contact() {
    const { t, i18n } = useTranslation();
    return (
        <Layout>
            <div className="h-300px bg-gray-900 d-flex align-items-center banner-height" style={{ backgroundImage: `url(${Roots({ is: "file" })}/banner/banner.jpg)` }}>
                <div className="container">
                    <div className="text-white fw-bolder fs-1">{t("banner.contact")}</div>
                </div>
            </div>
            <div className="container pt-10 pb-10">
                <div className="row">
                    <div className="col-lg-4 mb-4">
                        <div className="card card-flush">
                            <div className="card-body">
                                <div className="fs-4 fw-bolder">{t("contact.phone")}</div>
                                <div className="fs-3 fw-semibold">
                                    +41 (0)44 576 60 80
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card card-flush">
                            <div className="card-body">
                                <div className="fs-4 fw-bolder">{t("contact.email")}</div>
                                <div className="fs-3 fw-semibold">
                                    info@kompassgroup.ch
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card card-flush">
                            <div className="card-body">
                                <div className="fs-4 fw-bolder">{t("contact.address")}</div>
                                <div className="fs-3 fw-semibold">
                                    Schaufelbergerstrasse 57, 8055 Zürich
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card card-flush">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <span className="fs-6 fw-bold text-gray-600">{t("contact.gender")}</span>
                                        <input className="form-control" placeholder={t("contact.gender")} />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <span className="fs-6 fw-bold text-gray-600">{t("contact.name")}</span>
                                        <input className="form-control" placeholder={t("contact.name")} />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <span className="fs-6 fw-bold text-gray-600">{t("contact.email")}</span>
                                        <input className="form-control" placeholder={t("contact.email")} />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <span className="fs-6 fw-bold text-gray-600">{t("contact.phone")}</span>
                                        <input className="form-control" placeholder={t("contact.phone")} />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <span className="fs-6 fw-bold text-gray-600">{t("contact.message")}</span>
                                        <textarea className="form-control" placeholder={t("contact.message")} />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <button className="btn btn-kompass text-white w-100 rounded-2">
                                            {t("button.send")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 card-flush">
                        <div
                            className="h-100  background-biografy me-5 rounded-4"
                            style={{
                                backgroundImage: `url(${Roots({ is: "file" })}/contact/contact.webp)`
                            }}>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}
export default Contact;