import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from '../locales/de/translation.json';
import tr from '../locales/tr/translation.json';
i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: { translation: de },
            tr: { translation: tr }
        },
        lng: 'de',
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
