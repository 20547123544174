import { useNavigate } from "react-router-dom";
import { formatdate } from "../utils/formatdate";
import { Seo } from "../utils/seo";

export function Card({
    id = "",
    title = "",
    subtitle = "",
    location = "",
    date = "",
    time = "",
    image = "",
    effect = "hover-shadow"
}) {
    const router = useNavigate()

    return (
        <>
            <div className={`card ${effect} cursor-pointer`} onClick={() => { router(`${Seo(title)}/${id}`) }}>
                <div className="card-header card-image" style={{ backgroundImage: `url(${image})` }}>

                </div>
                <div className="card-body pt-5 pb-5 p-8">
                    <div className="d-flex mb-3">
                        <div>
                            <div className="card-name text-gray-700">{title}</div>
                        </div>
                        <div className="ms-1">
                            <div className="card-name text-gray-600">({subtitle})</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 rounded-1">
                        <div className="w-20px d-flex align-items-center justify-content-center">
                            <i className="fa-solid fa-location-dot fs-2 text-gray-600"></i>
                        </div>
                        <div className="w-100 d-flex align-items-center ms-2">
                            <div className="card-location text-gray-600 fs-6">{location}</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 rounded-1">
                        <div className="w-20px d-flex align-items-center justify-content-center">
                            <i className="fa-solid fa-calendar fs-2 text-gray-600"></i>
                        </div>
                        <div className="w-100 d-flex align-items-center ms-2">
                            <div className="card-date text-gray-600 fs-6">
                                {formatdate(date).dayOfWeek}, {formatdate(date).formattedDate}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3 rounded-1">
                        <div className="w-20px d-flex align-items-center justify-content-center">
                            <i className="fa-solid fa-clock fs-2 text-gray-600"></i>
                        </div>
                        <div className="w-100 d-flex align-items-center ms-2">
                            <div className="card-date text-gray-600 fs-6">{formatdate(time).formattedTime}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}