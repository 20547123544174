import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Roots } from '../config/root';
import { useTranslation } from 'react-i18next';
export const Language = createContext();

export const LanguageProvider = ({ children }) => {
    const [eventstate, seteventstate] = useState(1)
    const [eventdata, seteventdata] = useState([])

    const get_data = async (lang) => {
        const response = await axios.get(`${Roots({ is: "api" })}?params=event&language=${lang}`)
        seteventdata(response.data.data)
    }

    const { t, i18n } = useTranslation();
    useEffect(() => {
        seteventstate(i18n.language === "de" ? 1 : 2)
    }, [i18n.language])

    useEffect(() => {
        get_data(eventstate)
    }, [eventstate])
    return (
        <Language.Provider value={{ eventdata, seteventstate }}>
            {children}
        </Language.Provider>
    );
};