import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Roots } from '../config/root';
import { useTranslation } from 'react-i18next';
export const Faq = createContext();

export const FaqProvider = ({ children }) => {
    const [faqstate, setfaqstate] = useState(1)
    const [faqdata, setfaqdata] = useState([])
    const get_data = async (lang) => {
        const response = await axios.get(`${Roots({ is: "api" })}?params=faq&language=${lang}`)
        setfaqdata(response.data.data)
    }

    const { t, i18n } = useTranslation();
    useEffect(() => {
        setfaqstate(i18n.language === "de" ? 1 : 2)
    }, [i18n.language])

    useEffect(() => {
        get_data(faqstate)
    }, [faqstate])
    return (
        <Faq.Provider value={{ faqdata, setfaqstate }}>
            {children}
        </Faq.Provider>
    );
};