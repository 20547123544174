import { useTranslation } from "react-i18next";
import { Roots } from "../../config/root";
import { Layout } from "../../module/layout";
import { useContext } from "react";
import { Language } from "../../context/eventContext";
import { Card } from "../../library/card";

function Company() {
    const today = new Date();
    const { t, i18n } = useTranslation();
    const { eventdata } = useContext(Language)
    const eventdate = (eventdata || []).filter(d => new Date(d.date) <= today).sort((a, b) => new Date(b.date) - new Date(a.date))
    return (
        <Layout>
            <div className="h-300px bg-gray-900 d-flex align-items-center" style={{ backgroundImage: `url(${Roots({ is: "file" })}/banner/banner.jpg)` }}>
                <div className="container">
                    <div className="text-white fw-bolder fs-1">{t("banner.event")}</div>
                </div>
            </div>

            <div className="bg-gray-200">
                <div className="container">
                    <div className="row pt-10 pb-10 d-flex justify-content-center">
                        <div className="col-12 mb-6 mt-4">
                            <div className="section-title">{t('section.home.title')}</div>
                        </div>
                        {
                            (eventdate || [])
                                .map((d, x) => {
                                    return (
                                        <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-12 mb-4">
                                            <Card
                                                id={d.gid}
                                                title={d.title}
                                                subtitle={d.subtitle}
                                                location={d.addres}
                                                date={d.date}
                                                time={d.time}
                                                image={`${Roots({ is: "file" })}/event/${JSON.parse(d.media) ? JSON.parse(d.media).event : ""}`}
                                            />
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Company;