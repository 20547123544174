import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../assets/media/kompass-event-logo.png"
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export function Layout({ children }) {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        document.body.setAttribute("class", "page-loading");
        const timer = setTimeout(() => {
            setLoading(false);
            document.body.setAttribute("class", "app-default");
        }, 2000);
        return () => clearTimeout(timer);
    }, [location]);
    const Loader = () => {
        return (
            <div className="page-loader">
                <div className="d-flex text-center">
                    <div className="mt-2"><img src={Logo} style={{ height: "30px" }} /></div>
                    <div className="spinner-border ms-3 mt-1" style={{ width: "35px", height: "35px", borderWidth: "3px", color: "#2b3289" }}></div>
                </div>
            </div>
        );
    };


    return (
        <>
            <Helmet>
                <title>Kompass Events - Der Treffpunkt von Kunst und Kultur</title>
                <meta name="description" content="Veranstalter von unvergesslichen Momenten in der Schweiz, der die türkische Kunst und Kultur zum Leben erweckt. Ein Universum voller Konzerte, Theater und spezieller Shows." />
                <meta name="keywords" content="Kompass Events, Veranstaltungen in der Schweiz, Türkische Kunst, Türkische Kultur, Konzerte, Theater, Kulturelle Veranstaltungen, Festivals" />
            </Helmet>

            <ToastContainer />
            {loading ? <Loader /> : children}
        </>
    );
}