import { useLocation, useNavigate, useParams } from "react-router-dom"
import Logo from "../assets/media/kompass-event-logo.png"
import { useTranslation } from "react-i18next";
import '../i18n/i18n'
import { Language } from "../context/eventContext";
import { useContext, useState } from "react";
import { Menu } from "../context/menuContext";
import { About } from "../context/aboutContext";
import { Faq } from "../context/faqContext";

export function Header() {
    const router = useNavigate()
    const location = useLocation()
    const [show, setshow] = useState(false)
    const { menudata, setmenustate } = useContext(Menu)
    const { seteventstate } = useContext(Language)
    const { setaboutstate } = useContext(About)
    const { setfaqstate } = useContext(Faq)

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        seteventstate(lng === "de" ? 1 : 2)
        setmenustate(lng === "de" ? 1 : 2)
        setaboutstate(lng === "de" ? 1 : 2)
        setfaqstate(lng === "de" ? 1 : 2)

    };



    return (
        <>
            <div className="header border-bottom border-gray-200 position-fixed w-100 bg-white t-0" style={{ zIndex: 1050 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-2 col-xl-2 col-lg-7 col-md-7 col-4">
                            <div className="header-logo cursor-pointer" onClick={() => { router("/") }} >
                                <img src={Logo} className="h-30px" />
                            </div>
                        </div>
                        <div className="col-xxl-8 col-xl-7 col-lg-7 d-xl-block d-none">
                            <div className="header-menu">
                                {
                                    (menudata || []).filter((f) => { return (f.position === "header") }).map((d, x) => {
                                        return (
                                            <div
                                                key={`header-menu-list-${d.id}-list-${x}`}
                                                className={`fs-7 fw-bolder cursor-pointer text-gray-500 text-hover-gray-900 ms-10 me-5 ${location.pathname === d.path ? "text-gray-900" : ""}`}
                                                onClick={() => { router(d.path) }}>
                                                {d.name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-5 d-xl-block d-none">
                            <div className="header-item">
                                <div className="header-item-icon me-2">
                                    <button className="btn btn-outline text-gray-600 text-hover-white bg-hover-info bg-kompass">
                                        <i className="fa-solid fa-receipt"></i>
                                        {t("button.ticket")}
                                    </button>
                                </div>
                                <div className="header-item-icon">
                                    <button className="btn btn-outline text-gray-600  text-hover-gray-900"
                                        onClick={() => { i18n.language === "de" ? changeLanguage('tr') : changeLanguage('de') }}
                                    >
                                        <i className="fa-solid fa-earth-americas"></i>
                                        <span className="text-uppercase">
                                            {i18n.language === "de" ? "TR" : "DE"}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/**MOBILE */}
                        <div className="col-xxl-2 col-xl-3 col-lg-5 col-md-5 col-8 d-xl-none d-block d-flex justify-content-end">
                            <div className="header-item">
                                <div className="header-item-icon me-1">
                                    <button className="btn btn-outline text-gray-600  text-hover-gray-900 bg-kompass" style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                                        <i className="fa-solid fa-receipt"></i>
                                        {t("button.ticket")}
                                    </button>
                                </div>
                                <div className="header-item-icon me-1">
                                    <button
                                        className="btn btn-outline text-gray-600 text-hover-white"
                                        style={{ paddingLeft: "8px", paddingRight: "8px" }}
                                        onClick={() => { i18n.language === "de" ? changeLanguage('tr') : changeLanguage('de') }}
                                    >
                                        <i className="fa-solid fa-earth-americas"></i>
                                        {i18n.language === "de" ? "TR" : "DE"}
                                    </button>
                                </div>
                                <div className="header-item-icon" onClick={() => { setshow(true) }}>
                                    <button className="btn btn-outline text-gray-600 text-hover-white bg-hover-info" style={{ paddingLeft: "12px", paddingRight: "8px" }}>
                                        <i className="fa-solid fa-bars fs-3"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`bg-white drawer drawer-end ${show ? "drawer-on" : ""}`} style={{ width: "100%", zIndex: "1050" }}>
                <div className="card w-100 rounded-0">
                    <div className="card-header pe-5">
                        <div className="card-title">
                            <div className="d-flex justify-content-center flex-column me-3">
                                <img src={Logo} className="h-30px" />
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="cursor-pointer" onClick={() => { setshow(false) }}>
                                <i className="fa-regular fa-circle-xmark fs-3hx"></i>
                            </div>
                        </div>
                    </div>
                    <div className="card-body hover-scroll-overlay-y">
                        {
                            (menudata || []).map((d, x) => {
                                return (
                                    <div
                                        key={`header-menu-list-${d.id}-list-${x}`}
                                        className={`fs-4 fw-bolder cursor-pointer text-gray-500 text-hover-gray-900 mb-3 ${location.pathname === d.path ? "text-gray-900" : ""}`}
                                        onClick={() => { router(d.path) }}>
                                        {d.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="h-70px"></div>
        </>
    )
}