export function Roots({ is }) {

   /*   
     switch (is) {
        case "api": return "http://localhost/kompass/api.php";
        case "file": return "http://localhost/kompass/upload";
        default: return "";
    }
   */
    switch (is) {
        case "api": return "https://kompass.events/connect/api.php";
        case "file": return "https://kompass.events/connect/upload";
        case "upload": return "https://kompass.events/connect/upload.php";
        default: return "";
    }

    
}