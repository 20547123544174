import { useContext, useState } from "react";
import { Faq } from "../../context/faqContext";
import { Roots } from "../../config/root";

function Sss() {
    const { faqdata } = useContext(Faq)
    const [active, setactive] = useState(0)
    return (
        <>
            <div className="h-300px bg-gray-900 d-flex align-items-center" style={{ backgroundImage: `url(${Roots({ is: "file" })}/banner/banner.jpg)` }}>
                <div className="container">
                    <div className="text-white fw-bolder fs-1">FAQ</div>
                </div>
            </div>
            <div className="container">
                <div className="row pt-10 pb-10">
                    {

                        (faqdata || []).map((d, x) => {
                            return (
                                <div className="col-lg-12">
                                    <div className={`card mt-2 border border-gray-200 cursor-pointer ${active === x ? "bg-gray-800 text-white" : "bg-hover-gray-200 border-hover-gray-600"}`} onClick={() => { setactive(x) }}>
                                        <div className="card-body p-0">
                                            <div className="d-flex">
                                                <div className="w-100 d-flex h-60px align-items-center p-5">
                                                    <div className="fs-4 fw-bold">{d.name}</div>
                                                </div>
                                                <div className="d-flex h-60px align-items-center p-5">
                                                    <i className="fa-solid fa-sort-down fs-2hx h-40px"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3 mt-1" hidden={active === x ? false : true}>
                                        <div className="card-body">
                                            <div className="fs-4">
                                                {d.text}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="h-450px w-100 bg-dark background-fag" style={{ backgroundImage: `url(${Roots({ is: "file" })}/faq/faq-background.png)` }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Sss;