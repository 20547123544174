import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Roots } from '../config/root';
import { useTranslation } from 'react-i18next';
export const Menu = createContext();

export const MenuProvider = ({ children }) => {
    const [menustate, setmenustate] = useState(1)
    const [menudata, setmenudata] = useState([])

    const get_data = async (lang) => {
        const response = await axios.get(`${Roots({ is: "api" })}?params=page&language=${lang}`)
        setmenudata(response.data.data)
    }

    const { t, i18n } = useTranslation();
    useEffect(() => {
        setmenustate(i18n.language === "de" ? 1 : 2)
    }, [i18n.language])

    useEffect(() => {
        get_data(menustate)
    }, [menustate])
    return (
        <Menu.Provider value={{ menudata, setmenustate }}>
            {children}
        </Menu.Provider>
    );
};