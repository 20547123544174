import { useContext } from "react";
import { About } from "../../context/aboutContext";
import { Roots } from "../../config/root";
import { useTranslation } from "react-i18next";

function Company() {
    const { aboutdata } = useContext(About)
    const { t, i18n } = useTranslation();

    return (
        <>

            <div className="h-300px bg-gray-900 d-flex align-items-center banner-height" style={{ backgroundImage: `url(${Roots({ is: "file" })}/banner/banner.jpg)` }}>
                <div className="container">
                    <div className="text-white fw-bolder fs-1">{t("banner.about")}</div>
                </div>
            </div>

            {
                (aboutdata || []).map((d, x) => {
                    return (
                        <>
                            <div className="container">
                                <div className="row pt-10 pb-10">
                                    <div className="col-lg-6 col-12">
                                        <div
                                            className="h-100 card-flush background-biografy me-5 rounded-4"
                                            style={{
                                                backgroundImage: `url(${Roots({ is: "file" })}/about/${JSON.parse(d.media) ? JSON.parse(d.media).biography : ""})`
                                            }}>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="card-flush">
                                            <div className="fs-3" dangerouslySetInnerHTML={{ __html: d.biography }}></div>
                                            <div className="fs-2 fw-bolder">{d.owner}</div>
                                            <div className="fs-3">{d.position}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ backgroundColor: "#1b1a48" }}>
                                <div className="container">
                                    <div className="row pt-20 pb-20">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 d-flex align-items-center justify-content-center mb-5">
                                                    <img src={`${Roots({ is: "file" })}/about/${JSON.parse(d.media) ? JSON.parse(d.media).vimi[0] : ""}`} className="image-fluid about-image" />
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center">
                                                    <img src={`${Roots({ is: "file" })}/about/${JSON.parse(d.media) ? JSON.parse(d.media).vimi[1] : ""}`} className="image-fluid about-image mt-3 mb-3" />
                                                    <img src={`${Roots({ is: "file" })}/about/${JSON.parse(d.media) ? JSON.parse(d.media).vimi[2] : ""}`} className="image-fluid about-image mt-3 mb-3" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 d-flex align-items-center">
                                            <div className="row ms-3">
                                                <div className="col-lg-12 mb-2">
                                                    <div className="fs-1 fw-bolder text-white">{t("section.about.vision")}</div>
                                                </div>
                                                <div className="col-lg-12 mb-5">
                                                    <div className="fs-3 text-white" dangerouslySetInnerHTML={{ __html: d.vision }}></div>
                                                </div>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="fs-1 fw-bolder text-white">{t("section.about.mission")}</div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="fs-3 text-white" dangerouslySetInnerHTML={{ __html: d.mission }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
        </>
    )
}
export default Company;