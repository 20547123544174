import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function AutoUpper() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [pathname]);
    return null;
};
export function UpperButton() {
    const gototop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    return (
        <>
            <div className="upper-button border border-white shadow-sm cursor-pointer" onClick={() => { gototop() }}>
                <i className="fa-solid fa-sort-up fs-2hx h-20px text-white"></i>
            </div>
        </>
    );
};